// 
// buttons.scss
//

.btn {
    color: $white;
    font-family: $font-family-secondary;

    .mdi {
        &:before {
            margin-top: -1px;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

//
// light button
// 
.btn-light,.btn-white {
    color: $gray-900;
}

//
// Link buttons
//


// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
} 

// Make a button look and behave like a link
.btn-gradient {
    color: $white;
    background: $bg-gradient;
    @include hover {
        color: $white;
    }
    &:focus,
    &.focus {
        box-shadow: none;
    }
} 

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

// 
// Button Extra Small Size
// 

.btn-xs {
    padding: .2rem .6rem;
    font-size: .75rem;
    border-radius: .15rem;
}



/* Social Buttons */
.btn-facebook {
    color: $white !important;
    background-color: #3b5998;
  }
  .btn-twitter {
    color: $white !important;
    background-color: #00aced;
  }
  .btn-linkedin {
    color: $white !important;
    background-color: #007bb6;
  }
  .btn-dribbble {
    color: $white !important;
    background-color: #ea4c89;
  }
  .btn-googleplus {
    color: $white !important;
    background-color: #dd4b39;
  }
  .btn-instagram {
    color: $white !important;
    background-color: #517fa4;
  }
  .btn-pinterest {
    color: $white !important;
    background-color: #cb2027;
  }
  .btn-dropbox {
    color: $white !important;
    background-color: #007ee5;
  }
  .btn-flickr {
    color: $white !important;
    background-color: #ff0084;
  }
  .btn-tumblr {
    color: $white !important;
    background-color: #32506d;
  }
  .btn-skype {
    color: $white !important;
    background-color: #00aff0;
  }
  .btn-youtube {
    color: $white !important;
    background-color: #bb0000;
  }
  .btn-github {
    color: $white !important;
    background-color: #171515;
  }